import { jwtDecode } from 'jwt-decode'
import { iDictionary } from '../utils/iDictionary.ts'
import { iLoginState } from './iLoginState'
import { AxiosResponse } from 'axios'
import axiosClient from '../../utils/axiosClient'

export type Role = 'admin' | 'user' | 'blocked' | 'billingGroupManager'

// check if it is localhost or the dev environment
const tenantName =
  window.location.origin.indexOf('localhost') !== -1 ||
  window.location.origin.indexOf('dev') !== -1
    ? 'dev.'
    : window.location.origin.indexOf('dev')
      ? 'test.'
      : ''
const clientID =
  window.location.origin.indexOf('localhost') !== -1 ||
  window.location.origin.indexOf('dev') !== -1
    ? '0oaadtnerwmozZttd417'
    : window.location.origin.indexOf('qa')
      ? '0oaarwwagz2dqN6Du417'
      : '0oaasd7d2cYSyqrv8417'

const replyURL =
  window.location.origin +
  (window.location.origin.indexOf('localhost') !== -1 ? '/' : '')
// https://draegerb2c.b2clogin.com/draegerb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_a0018_signinrest&client_id=b37d4318-f197-4939-b60f-f286a9de116b&nonce=defaultNonce&redirect_uri=http://localhost:5173/&scope=openid&response_type=code+id_token&state=STATE-34565654546678797567432113
//export const loginURL = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/oauth2/v2.0/authorize?p=${policyName}&client_id=${clientID}&nonce=defaultNonce&redirect_uri=${replyURL}&scope=openid&response_type=code+id_token&state=STATE-34565654546678797567432113`
//export const logoutURL = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/logout?post_logout_redirect_uri=${replyURL}`

// Okta Links
export const loginURL = (state: string) =>
  `https://${tenantName}login.draeger.com/oauth2/default/v1/authorize?client_id=${clientID}&nonce=defaultNonce&redirect_uri=${replyURL}&scope=openid%20offline_access&response_type=code&state=${state}`

export const logoutURL = `https://${tenantName}login.draeger.com/oauth2/default/v1/logout?post_logout_redirect_uri=${replyURL}&id_token_hint=`

/**
 * Check if the user is logged in. If not, redirect to login page. Evaluate the code from the url if given.
 * @returns
 */
export async function evaluateTokens(): Promise<iLoginState> {
  // is the token in the url?
  const search = window.location.href.split('?')
  if (search.length > 1 && search[1].indexOf('=') !== -1) {
    const params = search[1].split('&')
    const dict: iDictionary = {}
    params.forEach((item) => {
      const parts = item.split('=')
      dict[parts[0]] = parts[1]
    })

    // received a code in url, send it to backend to get tokens
    if (hasKey(dict, 'code')) {
      let ret = { success: false, error: '' }
      const data = new URLSearchParams()
      data.append('code', dict['code'] as string)
      data.append('state', dict['state'] as string)

      try {
        const response = await axiosClient.post('/login/b2c', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-frontend-url': window.location.origin,
          },
        })

        setTokensFromResponse(response)

        ret = { success: response.status === 200, error: response.statusText }
      } catch (error) {
        console.error('Error:', error)
      }

      return {
        authToken: getToken() == null,
        refreshToken: getRefreshToken() == null,
      }
    }
  }

  // return false if no token is set, so the website stops loadingScreen while redirecting
  return { authToken: getToken() == null, refreshToken: getRefreshToken() == null }
}
function hasKey(obj: any, key: string) {
  return Object.keys(obj).indexOf(key) !== -1
}

export function validateToken(): iLoginState {
  return { authToken: getToken() != null, refreshToken: getRefreshToken() != null }
}

export function openLoginPage() {
  // #state=STATE is inside the url, so the user is already logged in
  if (window.location.hash.indexOf('state=STATE') === -1) {
    const state = { redirectUri: window.location.href }
    const stateAsBase64 = btoa(JSON.stringify(state))

    window.location.href = loginURL('STATE-' + stateAsBase64)
  }
}

// // TODO rework
// export function logoutButton() {
//     return <div className={styles.logoutButton2}>
//         <button className={`uib-link ${styles.logoutButton}`} onClick={() => {
//             localStorage.removeItem("id_token");
//             localStorage.removeItem("refresh_token");
//             //window.location.reload();
//             let url = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/logout?post_logout_redirect_uri=${HubPathRouting.getStartPage()}`
//             window.location.href = url
//             //window.location.href = getBackendApiURL() + "logout"
//         }}>Logout
//             <i className={`uib-icon uib-icon--exit ${styles.exitlogo}`}></i>
//         </button></div>
// }

/**
 * Sets the id_token and refresh_token from the response header.
 * @param dict Response from B2C
 */
export function setTokensFromHeader(dict: Response) {
  if (dict.headers.get('id_token') !== null) {
    setToken(dict.headers.get('id_token') as string)
  }
  if (dict.headers.get('refresh_token') !== null) {
    setRefreshToken(dict.headers.get('refresh_token') as string)
  }
}

export function getUser() {
  const decoded: any = jwtDecode(getToken())
  return {
    firstname: decoded.firstname,
    lastname: decoded.lastname,
    email: decoded.email_address,
    displayName: decoded.displayname,
    gaiaRole: JSON.parse(decoded.authLevel)?.user,
  }
}

export function getUserRole(): Role {
  try {
    const decoded: any = jwtDecode(getToken())
    const authLevelObject = JSON.parse(decoded.authLevel)
    return authLevelObject.user
  } catch (error) {
    return 'blocked'
  }
}

export function getRagDeveloperRole(): Role {
  try {
    const decoded: any = jwtDecode(getToken())
    const authLevelObject = JSON.parse(decoded.authLevel)
    return authLevelObject['user/RagDeveloper']
  } catch (error) {
    return 'blocked'
  }
}

export function getUserEmail() {
  try {
    const decoded: any = jwtDecode(getToken())
    return decoded.email_address
  } catch (error) {
    return false
  }
}

export function getUserKey() {
  try {
    const decoded: any = jwtDecode(getToken())
    return decoded.b2cid || decoded.sub
  } catch (error) {
    return false
  }
}

export function getToken(): string {
  return readCookie('id_token') as string
}
// JWT 1 hour lifetime
export function setToken(token: string) {
  saveAsCookie('id_token', token, 7)
}

export function setRefreshToken(token: string) {
  saveAsCookie('refresh_token', token, 7)
}

export function getRefreshToken() {
  return readCookie('refresh_token')
}

export function deleteTokens() {
  deleteCookie('id_token')
  deleteCookie('refresh_token')
}

export function getUserCountry() {
  try {
    const decoded: any = jwtDecode(getToken())
    return decoded.country.toLowerCase()
  } catch (error) {
    return false
  }
}

function saveAsCookie(name: string, value: string, days: number) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = ';expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + value + expires + ';path=/'
}

function readCookie(name: string) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim()
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function deleteCookie(name: string) {
  saveAsCookie(name, '', -1)
}

function removeExpiredCookies() {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    const expires = new Date()
    expires.setTime(expires.getTime() - 1)
    document.cookie = name + '=;expires=' + expires.toUTCString() + ';path=/'
  }
}

function setTokensFromResponse(response: AxiosResponse<any, any>) {
  if (response.data.id_token !== null) {
    setToken(response.data.id_token)
  }
  if (response.data.refresh_token !== null) {
    setRefreshToken(response.data.refresh_token)
  }
}
